<template>
  <v-content>
    <v-scroll-y-transition
      mode="out-in"
      appear
    >
      <router-view />
    </v-scroll-y-transition>

    <dashboard-core-footer />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>
